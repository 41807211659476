<template>
  <div>
    <LayoutCheckoutHeader />
    <!--<BasicBtErrorBoundary>-->
    <LayoutColorModePicker v-if="useFeatureState('cfg-blackweek-header')?.value === true"
      defaultColorMode="default" />

    <Modal
      v-if="showRatingRationale && useCurrentStore()?.value?.id != 'e_2879130' && !useAppSettingsCookie().value.hasAskedForRating">
      <AppPermissionRationale :permission-type="'RATING'" agree-label="Bewertung abgeben" later-label="Feedback abgeben"
        @agreed="sendToAppStore" @skipped="sendAppFeedback" @closed="showRatingRationale = !showRatingRationale" />
    </Modal>

    <main class="pt-4 lg:pt-32">
      <slot />
    </main>
    <!--</BasicBtErrorBoundary>-->
    <LayoutCheckoutFooter :isReservation="$route?.path?.includes('reservation')" />
  </div>
</template>
<script setup lang="ts">
import { useCurrentStore, useAppSettingsCookie } from "~/composables/states";
import { sendToAppStore, sendAppFeedback } from "~/composables/miscFunctions";
const showRatingRationale = useState('showRatingRationale', () => false);

onNuxtReady(() => {
  if (useRoute()?.query?.showRatingRationale == "true") {
    showRatingRationale.value = true;
  }
  // In Case Paypal Buttons did not show :/
  if(document.getElementById('buttons-container') == null)
    reloadNuxtApp({persistState:true,ttl: 30000})
});
</script>